.datatable {
  width: 60%;
  margin: 0 auto;
}

.App {
  max-width: 100%;
  overflow-x: hidden;
}

.table {
  padding-bottom: 70px;
}

.sticky-cta {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sticky-cta button {
  width: 100%;
  border-radius: 0;
  padding: 20px 0;
  min-height: auto;
  height: auto;
}

.toestel-kiezen-box {
  box-shadow: rgba(0,0,0,0.1) 0px 3px 16px;
  padding: 45px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background .5s ease;
}

.toestel-kiezen-box:hover {
  cursor: pointer;
  background: rgba(0,0,0,0.025);
  transition: background .5s ease;
}

.toestel-kiezen-box img {
  width: 100px;
  margin-bottom: 15px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}